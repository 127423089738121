<template>
  <div class="map-row">
    <!-- <div class="reminder">请先在地图中点选圈出片区位置</div> -->
    <!-- <div style="position:absolute;z-index:10;margin-left:20px;margin-top: 20px;"><el-button  size="mini" @click="goBacks()">返 回</el-button></div> -->
    <div class="mapLeft" :style="{ width: fullState ? '0' : '300px' }">
      <div
        class="Putaway"
        @click="Putaway()"
        :style="{ right: fullState ? '3px' : '290px' }"
      >
        <img
          src="@/assets/btn.png"
          :style="{ transform: fullState ? 'rotate(180deg)' : 'rotate(0deg)' }"
        />
      </div>
      <el-form
        ref="searchForm"
        class="searchForm"
        :model="searchForm"
        label-width="80px"
      >
        <el-form-item
          label-width="0px"
          class="searchBt"
          style="text-align: center; margin-top: 15px"
        >
        <!-- width:198px; -->
          <el-button
            style=" width:120px;height: 34px"
            size="mini"
            type="primary"
            class="addBtn"
            v-if="$anthButtons.getButtonAuth('addDistrict')" 
            @click="addNet()"
            >新增巡检区域</el-button
          >
          <el-button
            style="width:120px; height: 34px;background: #FFFFFF;
border: 1px solid #1082FF;color:#1082FF"
            size="mini"
            type="primary"
            class="addBtn"
            v-if="$anthButtons.getButtonAuth('ClockDistrict')" 
            @click="updateData"
            >更新GIS数据</el-button
          >
        </el-form-item>
      </el-form>
      <div
        :class="item.state ? 'pitchOn box-card lineBox' : 'box-card lineBox'"
        v-for="(item, index) in tableDataLine"
        :key="index"
      >
        <div class="examine" @click="editFun(item)">
          <p class="lineP">区域名称：{{ item.networkName }}</p>
          <el-tooltip class="item" effect="dark" :content="item.coverageName">
            <p class="lineP">图层：
              {{ item.coverageName }}
            </p>
          </el-tooltip>
          <p class="lineP" >
            打卡点：<span
              :style="{
                cursor: 'pointer',
                color: item.state ? '#ffffff' : '#409EFF',
              }"
              @click.stop="details(item)"
              >{{ item.count || 0 }}</span
            >
          </p>
        </div>
        <!-- <el-button-group> -->
        <el-button type="primary" size="mini" plain @click="addPoint(item)"
        v-if="$anthButtons.getButtonAuth('addClockDistrict')" 
          >新增打卡点</el-button
        >
        <el-button type="primary" size="mini" plain @click="edit(item)"
        v-if="$anthButtons.getButtonAuth('editClockDistrict')" 
          >编辑</el-button
        >
        <el-button type="danger" size="mini" plain @click="del(item)"
        v-if="$anthButtons.getButtonAuth('deleteClockDistrict')" 
          >删除</el-button
        >
        <!-- </el-button-group> -->
        <el-divider></el-divider>
      </div>
      <div class="pageBox">
        <el-pagination
          small
          :current-page="pageParams.current"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageParams.size"
          layout="prev, pager, next"
          :total="total"
          :page-count="pagerCount"
          @size-change="handleSizeChangeLine"
          @current-change="handleCurrentChangeLine"
        >
        </el-pagination>
      </div>
    </div>
    <!-- <div
        class="LeftFunction"
        v-show="SelectMenuData"
        :style="{overflow:'auto',width: '268px' }"
      >
      <div style="height:39px">
      <div style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;left: 0px;">图层管理</div>
      <i
            class="el-icon-circle-close"
            style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;right: 0px;"
            @click="clone()"
          ></i>
      </div>
        <div class="popoverCon">
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree" 
            :default-checked-keys="checkedKeys"
            default-expand-all
            :props="defaultProps"
            @check="checkClick"
          > 
          
          </el-tree>
        </div>
      </div> -->
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
      <subMap
        ref="subMap"
        :path="path"
        @setLocation="setLocation"
        :layers="layers"
      />

      <div class="dialogBoxedt" v-if="dialogTableVisible">
        <div class="gaine">
          <!-- <i class="el-icon-close modality" @click="close()"></i> -->
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            class="formBox"
          >
            <el-form-item label="区域名称" prop="networkName">
              <el-input
                v-model="form.networkName"
                maxlength="30"
                show-word-limit
                placeholder="请输入区域名称"
                class="widthes"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="executorPeopleId">
              <el-input
                v-model.number="form.sortNum"
                placeholder="请输入数字0-9999"
                clearable
                type="number"
                maxlength="4"
                @input="
                  form.sortNum =
                    form.sortNum > 9999
                      ? 9999
                      : form.sortNum < 0
                      ? 0
                      : form.sortNum
                "
                style="width: 434px; height: 40px"
              ></el-input>
            </el-form-item>
            <el-row style="text-align: center; margin-top: 53px">
              <el-button type="primary" @click="onSubmit" class="buttones"
                >确 认</el-button
              >
              <!-- <el-button class="buttones" @click="close()">重新绘制</el-button> -->
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog title="查看" width="60%" :visible.sync="eventVisible">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        height="300"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          property="clockName"
          label="打卡点"
          align="center"
          show-overflow-tooltip
        >
        <template slot-scope="{ row }">
          <span @click="gotoEdit(row)" class="blue">{{row.clockName}}</span>
        </template>
        </el-table-column>
        <el-table-column
          property="clockAddress"
          label="地址"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <div style="text-align: left">
        <el-pagination
            :current-page="paramsData.current"
            :background="true"
            :page-size="paramsData.size"
            layout="total,prev,pager,next,jumper"
            :total="totalJUME"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="eventVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <point :showForm="showFormPoint" @closePDraw="closePDraw" :addForm="form" />
  </div>
</template>

<script>
import { getUserList } from "@/RequestPort/maintenance/task";
import { queryFeatrue } from "@/apis/geo";
import { networkDetail, getNetwork, delNetwork } from "@/apis/commonType";
import { ChangePlan, findTreeMap,networkUpdate } from "@/RequestPort/Inspection/inspePlan";
import subMap from "@/views/mapTool/subMapPlans";
import point from "@/views/linegasMap/commonForm/point";
export default {
  components: { subMap, point },

  name: "inspePlanAdd",
  data() {
    return {
      path: [],
      // 表单提交
      form: {},
      pagerCount: 3,
      pageParams: {
        current: 1,
        size: 100,
      },
      // 片区列表
      tableDataLine: [],
      layers: "'patrol_events','patrol_equipments','patrol_lines'",
      // 编辑计划弹窗
      dialogTableVisible: false,
      rules: {
        networkName: [{ required: true, message: "请选择", trigger: "change" }],
        executorPeopleId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      excutorDownArray: [],
      // 管线管理弹窗的展示隐藏
      fullState: false,
      searchForm: {},
      tableData: [],
      total: 0,
      data: [],
      openKeys: [],
      // 选择值
      checkedKeys: [],
      // 展开不展开
      isExpand: true,
      // 对应的值
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeList: [],
      treeChildrenMap: new Map(),
      treeChildrenData: [],
      showFormPoint: false,
      // 图层弹窗
      // SelectMenuData:false,
      // 片区id
      districtId: 0,
      paramsData: {
        current: 1,
        size:6,
      },
      eventVisible: false,
      network:'',
      dataList:[],
      totalJUME:0,
      polygon:null,
      switch:false
    };
  },
  destroyed() {
    this.$eventBus.$off("transferAddForm");
     this.$eventBus.$off("districtDot");
    // this.$eventBus.$off("Clicktree");
  },
  mounted() {
    let that = this;
    this.$eventBus.$emit("pageStart", "district");
    // 提交获取addform坐标
    this.$eventBus.$on("transferAddForm", (data) => {
      this.form.location = this.setPolygon(data);
    });
    // 打开打卡点
    this.$eventBus.$on("districtDot", (data) => {
      console.log(data,"districtDot")
      this.form = data
      this.showFormPoint = true
    });
    // this.$eventBus.$on("Clicktree", (data) => {
    //     this.SelectMenuData = !this.SelectMenuData
    //   });

    // this.getFindTree()
    /**
     * 获取片区列表
     */
    this.SearchLineDataChange();
  },
  methods: {
    // async getFindTree() {
    //     this.treeChildrenData = []
    //     // let p = { companyId: this.enterValue.value }
    //     let res = await findTreeMap({sysType:1})
    //     let restwo = await findTreeMap({sysType:2})
    //     this.data=[{
    //       id: 0,
    //       children:res.data,
    //       isDelete: 1,
    //       layerType: 1,
    //       name: "gis图层",
    //       parentId: -1,
    //       weight: 1},
    //       {
    //       id: 1,
    //       children:restwo.data,
    //       isDelete: 1,
    //       layerType: 1,
    //       name: "巡线图层",
    //       parentId: -1,
    //       weight: 1}]
    //    this.getTreeSee(this.data)
    //   try {
    //   } catch (error) {
    //     this.$message.error(error.message)
    //   }
    // },
    /**
     * 更新数据
     */
    updateData(){
      // 开关开启
      if(this.switch) {
        return
      } else {
        this.switch = true
        setTimeout(() => {
          this.switch = false
        },6000*2*10)
        networkUpdate().then(res => {
          this.$message.success("更新成功");
        })
      }
      
    },
    /**
     * 点击打卡点
     */
    gotoEdit(row){
      // this.closePDraw()
      if(this.polygon){
      this.$refs.subMap.map.remove(this.polygon)
      }
      this.$eventBus.$emit('xfSwitch',true)
      let lnglat = new AMap.LngLat(row.location.split(' ')[0], row.location.split(' ')[1])
      let data={
        lnglat,
        data:row,
        type:'view_all_point',
        start:'linqing:patrol_clock'
      }
      this.$eventBus.$emit('patrolLinesfun', data )
      this.eventVisible = false
    },
    addPoint(item) {
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
      this.$eventBus.$emit("RemoveArea");
        this.network = !item.id ?'nulls' : 'haves'
      this.districtId = item.id;
      // this.$eventBus.$emit("coverageOpen")
      // this.$eventBus.$emit("addDian")
      this.$refs.subMap.getLocation("marker");
    },
    // 开始划线
    addNet() {
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
      this.$refs.subMap.getLocation("polygon");
      this.showFormPoint = false
      this.$eventBus.$emit("coverageOpen")
      // 关掉弹窗详情
      this.$eventBus.$emit("RemoveArea");
      // 框选查询选中片区
      this.$eventBus.$emit("Boxselection",1);
    },
    /**
     * 删除片区
     */
    async del(item) {
      this.closePDraw();
      let msg = "确定删除巡检区域吗？";

      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        delNetwork(params).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.$eventBus.$emit("RemoveArea");
          }
          // this.pageParams.current = 1
          this.closePDraw();
           this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking'});
        });
      });
    },
    details(item) {
      this.eventVisible = true;
      let dataList = []
      let n = 1
      item.patrolClockList.forEach((el,index) => {
        if(!dataList[n-1]){
          dataList[n-1] = []
        }
        if(index<n*6){
          dataList[n-1].push(el)
        } else {
          n++
          if(!dataList[n-1]){
            dataList[n-1] = []  
          }
          dataList[n-1].push(el)
        }
      })
      this.totalJUME = item.patrolClockList.length
      this.dataList = dataList
      this.tableData = dataList[0];
    },
    edit(data) {
      // 关掉弹窗详情
      this.$eventBus.$emit("RemoveArea");
      // 打开图层
      this.$eventBus.$emit("coverageOpen")
      // 编辑关掉
      this.showFormPoint = false
      this.$eventBus.$emit("vectorEdit", {
        data: data,
        type: "patrol_plan",
      });
    },
    /**
     * 关闭
     */
    clone() {
      this.SelectMenuData = false;
    },
    /**
     * 点击树
     */
    checkClick(val) {
      return this.$refs.subMap.$refs.search.checkClick()
    },
    /**
     * 获取列表
     */
    SearchLineDataChange() {
      if(this.tableDataLine.length == 1 && (this.pageParams.current != 1)) {
        this.pageParams.current -= 1 
      }
      getNetwork(this.pageParams).then((resale) => {
        this.tableDataLine = resale.data.records;
        this.total = resale.data.total;
      });
    },
    checkhighlight(){
      if(this.polygon) {
        this.$refs.subMap.map.remove(this.polygon)
      }
    },
    /**
     * 点击片区
     */
    editFun(item) {
      if(this.polygon) {
        this.$refs.subMap.map.remove(this.polygon)
      }
      this.closePDraw()
      // this.$refs.subMap.$refs.toolInfo.handleClose()
      // 关闭编辑弹窗
      this.$eventBus.$emit("typeEdit");
      // 关闭图层
      this.$eventBus.$emit('coverageclose')
      //  this.$refs.subMap.loctiones(item.location)
      this.tableDataLine.forEach((el) => {
        if (el.id == item.id) {
          el.state = true;
        } else {
          el.state = false;
        }
      });
      this.$forceUpdate();
      this.$eventBus.$emit("xfSwitch", true);
      let lnglat;
      // if (item.location && item.location.length > 0) {
      //   lnglat = new AMap.LngLat(item.location[1][0], item.location[1][1]);
      // } else {
      //   lnglat = new AMap.LngLat(item.coordinate[0][0], item.coordinate[0][1]);
      // }
      this.polygon = new AMap.Polygon({
        path: item.location,
        fillColor:'red', // 颜色替换
        fillOpacity :0.5,
        strokeColor:'red'
      })
      //this.$refs.subMap.map.remove(this.polygon)
      let zoomandcenter = this.$refs.subMap.map.getFitZoomAndCenterByOverlays([this.polygon])
      this.$refs.subMap.map.add(this.polygon)
      this.$refs.subMap.map.setZoomAndCenter(Math.floor(zoomandcenter[0]),zoomandcenter[1])
      let data = {
        lnglat:zoomandcenter[1],
        data: item,
        type: "patrol_networks",
        start:'linqing:patrol_networks'
      };
      this.$eventBus.$emit("patrolLinesfun", data);
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    Putaway() {
      this.fullState = !this.fullState;
    },
    setformatter(row) {
      let arr = ["停用", "在用", "废弃"];
      return arr[row.equipmentStatus];
    },
    goBacks() {
      window.history.go(-1);
    },
    setLocation(path) {
      // let location = path;
      // this.$eventBus.$emit("mapRegion", location);
      // this.form={location: location};
      let geocoder;
      let point = [];
      point[0] = path.lng;
      point[1] = path.lat;
      AMap.plugin(["AMap.Geocoder"], function () {
        geocoder = new AMap.Geocoder({
          radius: 500,
        });
      });
      var address = "";
      
      let points = this.centerPointGetFourPoint(path);
      let a = points.leftBottomPoint[0];
      let b = points.leftBottomPoint[1];
      let c = points.upRightPoint[0];
      let d = points.upRightPoint[1];
      let parames = {
        SERVICE: "WMS",
        VERSION: "1.1.0",
        REQUEST: "GetFeatureInfo",
        FORMAT: "image/png",
        TRANSPARENT: true,
        QUERY_LAYERS: "linqing:patrol_networks",
        LAYERS: "linqing:patrol_networks",
        exceptions: "application,Fvnd.ogc.se_inimage",
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 10,
        X: 50,
        y: 50,
        SRS: "EPSG:4326",
        WIDTH: 101,
        HEIGHT: 101,
        // ...cql,
        BBOX: a + "," + b + "," + c + "," + d,
        propertyName: "id",
      };
      let that = this;
      if(that.network == 'haves'){
        // 查询所有片区
      queryFeatrue(parames).then((res) => {
        that.showFormPoint = true;
        let number = 0
        if (res.features[0]) {
          res.features.forEach(el => {
            // 是选择的片区进行添加
            if (el.properties.id == that.districtId) {
              number += 1
              let p = {
                id: that.districtId,
              };
              networkDetail(p).then((reslat) => {
                if (reslat.data.networkName) {
                  geocoder.getAddress(point, function (status, result) {
                    if (status === "complete" && result.regeocode) {
                      address = result.regeocode.formattedAddress;
                      that.$eventBus.$emit("coordinatedot", {
                        path,
                        networkName: address,
                        id: that.districtId,
                      });
                    } else {
                      console.log("根据经纬度查询地址失败");
                    }
                  });
                  
                  //  localStorage.setItem('networkName', reslat.data.networkName)
                }
              });
            } 
          })
          if(number == 0) {
           that.$message.error("请在区域内定位");
          }
        } else {
          that.$message.error("请在区域内定位");
        }
      
      });
      } else {
        that.showFormPoint = true;
        geocoder.getAddress(point, function (status, result) {
        if (status === "complete" && result.regeocode) {
          
          address = result.regeocode.formattedAddress;
          that.$eventBus.$emit("coordinatedot", {
            path,
            networkName: address,
            id: that.districtId,
          });
        } else {
          console.log("根据经纬度查询地址失败");
        }
      });
        
      }
      // let arr = point
      // arr.push(point[0])
      // let newArr = []
      // let strArr = []
      // arr.forEach(item => {
      //   newArr.push([item.lng, item.lat])
      //   strArr.push(`${item.lng} ${item.lat}`)
      // })
      // this.form.polygon = String(strArr)
      // let polygonObj = polygon([newArr])
      // let center = centerOfMass(polygonObj)
      // this.form.lon = center.geometry.coordinates[0]
      // this.form.lat = center.geometry.coordinates[1]
    },
    centerPointGetFourPoint(lnglat) {
      let margin = 100;
      let Pixel = this.$refs.subMap.map.lngLatToContainer(lnglat);
      let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y);
      const newLnglat = this.$refs.subMap.map.containerToLngLat(newPixel);

      let side = AMap.GeometryUtil.distance(lnglat, newLnglat);

      const centerPoint = lnglat;
      const upLeftPoint = centerPoint.offset(
        -parseInt(side / 2),
        parseInt(side / 1.9)
      );
      const upRightPoint = centerPoint.offset(
        parseInt(side / 2),
        parseInt(side / 1.9)
      );
      const leftBottomPoint = centerPoint.offset(
        -parseInt(side / 2),
        -parseInt(side / 1.7)
      );
      const rightBottomPoint = centerPoint.offset(
        parseInt(side / 2),
        -parseInt(side / 1.7)
      );
      return {
        upLeftPoint: [upLeftPoint.lng, upLeftPoint.lat],
        upRightPoint: [upRightPoint.lng, upRightPoint.lat],
        leftBottomPoint: [leftBottomPoint.lng, leftBottomPoint.lat],
        rightBottomPoint: [rightBottomPoint.lng, rightBottomPoint.lat],
      };
    },
    //  getTreeSee(data) {
    //   data.forEach(el => {
    //     if (!this.activeLayer && el.layerType != 1) {
    //       this.activeLayer = el.id
    //      // this.setStroeCurrent(el.id)
    //     }
    //     if (this.treeChildrenMap.has(el.id)) {
    //       this.$set(el, 'isSee', this.treeChildrenMap.get(el.id))
    //       if (this.treeChildrenMap.get(el.id) == 2 && !el.children) this.checkedKeys.push(el.id)
    //     } else {
    //       this.$set(el, 'isSee', 2)
    //       this.treeChildrenMap.set(el.id, 2)
    //       this.checkedKeys.push(el.id)
    //     }
    //     this.treeChildrenData.push(el)

    //     if (el.children) {
    //       this.getTreeSee(el.children)

    //     }
    //   })
    // },
    /**
     * 片区校验是否是正确的片区
     */
    setPolygon(path) {
      if (path && path.length >= 3) {
        var area = AMap.GeometryUtil.ringArea(path);
        if (area <= 0) {
          this.$nextTick(() => {
            this.$refs.subMap.coloseForm();
          });
          this.$message.error("请绘制正确的片区");
          return;
        }
      } else {
        this.$nextTick(() => {
          this.$refs.subMap.coloseForm();
        });

        this.$message.error("请绘制正确的片区");
        return;
      }
      path.push(path[0]);
      let newPath = [];
      path.forEach((item, index) => {
        let nextItem = path[index + 1];
        if (nextItem) {
          if (nextItem.lng == item.lng && item.lat == nextItem.lat) {
          } else {
            newPath.push(item);
          }
        } else {
          newPath.push(item);
        }
      });

      return newPath;
    },
    setMapClick() {
      this.$refs.subMap.getLocation();
    },
    /**
     * 选择人员
     */
    // personnel(val){
    //   this.form.executorPeopleId = val
    //   // this.$set(this.form,"executorPeopleId",val)
    // },
    /**
     * 添加巡线计划
     */
    getcheck() {
      let check;
      if (this.$store.getters.layerTree.length > 0) {
        return this.$store.getters.layerTree;
      }
      // let check=this.$refs.subMap.$refs.search.tree.getCheckedNodes()
      // if (check && check.length > 0) {
      //   this.checkedKeys = [];
      //   this.getTreeSee(check);
      //   return this.checkedKeys;
      // }
      return [];
    },
    /**
     * 添加片区
     */
    onSubmit() {
      this.$eventBus.$emit("gainAddForm");
      this.$refs.form.validate((valid) => {
        if (valid) {
          let f = {
            ...this.form,
          };
          let check = this.getcheck();
          if (check && check.length > 0) {
            f.coverage = check.toString();
          }
          ChangePlan(f).then((res) => {
            this.dialogVisible = false;
            this.$message.success(res.msg);
            this.closePDraw();
            //     this.$router.push({ path: "/home/inspePlans" });
          });
        }
      });
    },
    closePDraw() {
      this.$refs.subMap.clearLocation();
      this.$eventBus.$emit("closeEdit");
      this.SearchLineDataChange();
      if(this.polygon){
      this.$refs.subMap.map.remove(this.polygon)
      }
    },
    /**
     * 关闭弹窗
     */
    close() {
      if(this.polygon){
      this.$refs.subMap.map.remove(this.polygon)
      }
      // this.dialogTableVisible = false;
      this.form = {};
      this.$eventBus.$emit("closeEdit", { type: "patrol_networks_jihe" });
    },
    /**
     * 列表下面的分页
     */
    handleSizeChangeLine(val) {
      this.pageParams.size = val;
      this.SearchLineDataChange();
    },
    handleCurrentChangeLine(val) {
      this.pageParams.current = val;
      this.SearchLineDataChange();
    },
    handleSizeChange(val) {
      this.paramsData.current = 1;
      this.paramsData.size = val;
    },
    handleCurrentChange(val) {
      this.tableData = this.dataList[val-1]
      this.paramsData.current = val;
    },
  },
};
</script>

<style lang="less" scoped>
.blue{color:#409EFF;cursor: pointer;}
/deep/.el-dialog__body{
    padding: 0px 20px;
}
// 小盒子
.lineBox {
  margin: 0;
  height: 120px;
  padding: 5px 20px;
  .el-divider--horizontal {
    margin: 11px 0 0;
  }
  .lineP {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    margin-top: 5px;
  }
  .lineB {
    margin-top: 10px;
  }
}
// 选中
.pitchOn {
  background: #2c9ef7;
  color: #ffffff;
  .el-button--primary.is-plain:hover{
    background: #b4d7ff;
    color: #006ce3;
  }
  .el-button--danger.is-plain:hover{
    background: #f4c3c3;
    color: #e93739;
  }
}
.map-row {
  position: relative;
  // 弹窗盒子
  .dialogBoxedt {
    background: #ffffff;
    position: absolute;
    top: 30%;
    right: 2%;
    width: 420px;
    height: 363px;
    border-radius: 6px;
    // 内容盒子
    .gaine {
      position: relative;
      // 标题
      .fialogTitle {
        height: 60px;
        line-height: 60px;
        color: #0c235b;
        text-align: left;
        padding-left: 30px;
        font-size: 24px;
      }
      // 关闭按钮
      .modality {
        width: 19px;
        height: 19px;
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
      }
      // form盒子
      .formBox {
        margin: 53px 30px 10px;
        // 输入区域宽
        .widthes {
          width: 230px;
        }
        // 底部按钮宽
        .buttones {
          width: 160px;
        }
      }
    }
  }
}
.mapRight {
  width: auto;
  margin-left: 0px;
  position: relative;
  overflow: hidden;
}
// 右边总盒子
.mapLeft {
  overflow: auto;
  height: calc(100vh - 150px);
  width: 300px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
  float: right;

  // label内容
  /deep/.el-form-item__label {
    color: #0c235b;
  }
  // 搜索input
  /deep/.el-input__inner {
    height: 36px;
  }
  // 表格行高
  /deep/.el-table__row {
    line-height: 42px;
  }
  // 开关
  /deep/.el-switch__core {
    width: 33px !important;
    height: 16px;
  }
  // 开关按钮
  /deep/.el-switch__core:after {
    width: 12px;
    height: 12px;
  }
  // 开关按钮
  /deep/.el-switch.is-checked .el-switch__core::after {
    margin-left: -12px !important;
  }
  // 分页
  .pageBox {
    width: 100%;
    text-align: center;
    margin-top: 25px;
    .el-pagination {
      margin: 15px 0 40px;
    }
  }
}
// 收回按钮
.Putaway {
  background: #737e95;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  // right: 5.25rem;
  // right: 340px;
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.56rem;
}
// 按钮样式
.el-button--primary.is-plain {
  border-color: #ecf5ff;
  margin: 10px 1px 0;
}
.el-button--danger.is-plain {
  border-color: #fef0f0;
  margin: 10px 1px 0;
}
// 图层弹框样式
.LeftFunction {
  padding: 0 20px;
  position: absolute;
  content: "";
  z-index: 999;
  left: 90px;
  top: 1rem;
  background: #fff;
  width: 300px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  max-height: 500px;
  // overflow:auto;
}
.LeftFunction::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: #ccc;
  border-color: transparent;
  border-width: 1.5px;
  background-clip: padding-box;
}
.LeftFunction::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}
</style>
