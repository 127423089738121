<template>
  <!-- <div >    
   
          <div class="formBox" v-if="showForm">
              <span class="t">{{addForm.id?'编辑打卡点':'新增打卡点'}}</span>
              <el-divider></el-divider>
                  <el-form ref="addForm" class="searchForm"  :model="formUpdata" :rules="rules" label-width="100px">
                        <el-form-item label="打卡点名称" prop="clockkName" size="mini">
                          <el-input v-model="formUpdata.clockkName"  placeholder="请输入打卡点名称"></el-input>
                        </el-form-item>
                        <el-form-item label="地址" prop="clockAddress" size="mini" label-width="100px">
                           {{formUpdata.clockAddress}}
                        </el-form-item>
                        <el-form-item label-width="0px" class="searchBt">
                          <el-button
                            size="mini"
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            size="mini"
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
                        </el-form-item>
                  </el-form>
          </div>

  </div> -->
  <div class="dialogBox" v-if="showForm">
    <div class="gaine">
      <div class="fialogTitle">{{addForm.id?'编辑打卡点':'新增打卡点'}}</div>
      <!-- <i class="el-icon-close modality" @click="handleClose()"></i> -->
      <el-form
        ref="addForm"
        :model="formUpdata"
        :rules="rules"
        label-width="80px"
        class="formBox"
         
      >
        <el-form-item label="打卡点名称" prop="clockName" label-width="100px">
          <el-input v-model="formUpdata.clockName"  placeholder="请输入打卡点名称"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="clockAddress" size="mini" label-width="100px">
                           {{formUpdata.clockAddress}}
                        </el-form-item>
        <el-row style="text-align: center; margin-top: 53px">
          <el-button
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {saveClock } from '@/apis/commonType'
export default {
  name: 'formEdit',
  props:{
    showForm:{
      type:Boolean,
      default:false
    }, 
    addForm:{
      type:Object,
      default: function(){
        return {}
      }
    },
    polyline1:{
      type:Object,
      default: null
    }
  },
  data() {
    return {
      name: '打卡点',
      rules: {
        clockName: [
          { required: true, message: '请输入打卡点名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ]
      },
      formUpdata:{}
    }
  },
  computed: {},
  destroyed() {
    this.$eventBus.$off("coordinatedot");
  },
  watch: {
    'addForm' (val){
        if(this.addForm.id){
          this.formUpdata = this.addForm
          this.formUpdata.lng = this.addForm.coordinate[0]
          this.formUpdata.lat = this.addForm.coordinate[1]
        }
    }
  },
  mounted() {
    // if(localStorage.getItem('networkName')){
    //   this.addForm.clockAddress = localStorage.getItem('networkName')
    // }
    this.$eventBus.$on("coordinatedot", (data) => {
      this.$set(this.formUpdata,'clockAddress',data.networkName)
       this.$set(this.formUpdata,'networkId',data.id)
      this.formUpdata ={...this.formUpdata,... {lng:data.path.lng}}
      this.formUpdata = {...this.formUpdata,... {lat:data.path.lat}}
    })
    
    // let inputArray = document.getElementsByClassName('el-input__inner')
    // inputArray.forEach((item,index)=>{
    //   item.setAttribute('maxlength',5)
    // })
  },
  methods: {
    resetFields(){
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields()
      }
    },
    save(){
      
    
      this.$refs['addForm'].validate(valid => {
          if (valid) {              
               let parames={...this.addForm,...this.formUpdata}
               if(!this.formUpdata.clockAddress) {
                this.$message.error('请选择对应片区')
                return
               }
                saveClock(parames).then(res => {
                  if(res.code==200){
                    this.$message.success('操作成功')
                    this.formUpdata = {}
                    this.$parent.showFormPoint=false
                    this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking'});
                    this.$emit('closePDraw')
                  }                
                })
          }
      })
      
    },
    closePDraw(){
      this.formUpdata = {}
      this.$parent.showFormPoint=false
      this.$emit('closePDraw')
    }
   
  }
}
</script>

<style lang="less" scoped>

// .searchForm{margin:15px 15px 0 5px;}
// .searchBt{text-align: center;}
// .formBox{position:absolute;right:350px;top:100px;background: #fff;width: 300px;padding-top:10px;z-index: 3;
// .t{padding-left:10px;font-size: 16px;}
// ::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
// .el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
// }
// }
.map-row {
  position: relative;
  // 弹窗盒子
  .dialogBox {
    background: #ffffff;
    position: absolute;
    top: 30%;
    right: 20%;
    width: 420px;
    z-index: 10;
    // height: 363px;
    border-radius: 6px;
    // 内容盒子
    .gaine {
      position: relative;
      // 标题
      .fialogTitle {
        height: 60px;
        line-height: 60px;
        color: #0c235b;
        text-align: left;
        padding-left: 30px;
        font-size: 24px;
      }
      // 关闭按钮
      .modality {
        width: 19px;
        height: 19px;
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
      }
      // form盒子
      .formBox {
        margin: 0px 30px 10px;
        // 输入区域宽
        .widthes {
          width: 230px;
        }
        // 底部按钮宽
        .searchBtn {
          width: 160px;
        }
      }
    }
  }
}
</style>
